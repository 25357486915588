.cursor-hover:hover {
  cursor: pointer;
}
.padding-sm {
  padding: 5px;
}

.btn-drop-down {
  min-width: 80px;
  font-size: 14px;
  padding: 0;
}

.btn-drop-down-items {
  padding: 5px 15px;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.header-logo {
  width: 140px;
  height: 40px;
}

.custom-modal {
  z-index: 100;
}

.form-partition {
  border-top: 1px solid #c0c0c2;
  width: 100%;
  text-align: center;
  margin: 30px auto 0;
}

.radius {
  border-radius: 4px;
}

.top-radius {
  border-radius: 4px 4px 0px 0px;
}

.wrapper-footer {
  width: 100%;
  background-color: #dedede;
}

.wrapper-gray {
  width: 100%;
  background-color: #f0f0f0;
}

.wrapper-white {
  width: 100%;
  background-color: white;
}

.shadow {
  box-shadow: 0px 1px 2px rgba(128, 128, 128, 0.2);
}

.full-shadow {
  box-shadow: 1px 1px 2px rgba(128, 128, 128, 0.2),
    -1px -1px 2px rgba(128, 128, 128, 0.2);
}

.main-footer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  margin: 60px 0;
}
