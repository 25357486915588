.voucher{
    width: 100%;
}

.vouchercontainer{
    border-radius: 8px;
    box-shadow: 1px 2px rgba(128, 128, 128, 0.2),
    -1px -1px 2px rgba(128, 128, 128, 0.2);
    padding: 24px;
    background-color: #fff;
}

.title{
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    font-style: normal;
    color: rgb(85, 194, 217);
    text-transform: uppercase;
}

.form{
    max-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.formgroup{
    width: 100%;

}

.formgroup> input, select{
    width: 100%;
    height: 45px;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    padding: 0 10px;
    border-radius: 4px;
    border:1px solid gray;
}

.formgroup>input:focus{
    border:2px solid rgb(85, 194, 217);
    border-radius: 4px;
}

.btn{
    background: none;
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer;
    color: rgb(85, 194, 217);
    padding: 10px;
    transition: all .3s ease-in;
}

.btn:hover{
    background: #f0eeee;
}   


.vouchers{
   max-width: 350px;
   margin: auto;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   flex-direction: column;
}


.list{
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
    text-transform:capitalize;
}

.list:hover{
    color: rgb(85, 194, 217);
}