.upsell{
    width: 100%;
}

.upsellcontainer{
    border-radius: 8px;
    box-shadow: 1px 2px rgba(128, 128, 128, 0.2),
    -1px -1px 2px rgba(128, 128, 128, 0.2);
    padding: 24px;
    background-color: #fff;
    width: 100%;
}

.title{
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    font-style: normal;
    color: rgb(85, 194, 217);
    text-transform: uppercase;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-top: 50px;
}

.formgroup{
    width: 100%;

}
.multipleform{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    width: 100%;
}

.formgroup> input, select{
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    padding: 0 10px;
    border-radius: 4px;
    border:1px solid gray;
}

.formgroup>input:focus{
    border:2px solid rgb(85, 194, 217);
    border-radius: 4px;
}

.upsellbtn{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.btn{
    background: none;
    outline: none;
    border: none;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer;
    color: rgb(85, 194, 217);
    padding: 10px;
    transition: all .3s ease-in;
}

.btn:hover{
    background: #f0eeee;
}   

.imageuploadsection{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.upsellfileupload{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    border-radius: 4px;
    border:1px solid gray;
    cursor: pointer;
}

.fileuploadicon{
    padding: 10px;
    cursor: pointer;
    transition: all .3s ease;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.upsellfileupload:hover .fileuploadicon{
    background: rgba(211, 211, 211, 0.658);
}

.filewrapper{
    margin: auto;
    max-width: 1024px;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    white-space: nowrap;
}


.files{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    border:1px solid gray;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}

.files > img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media (max-width: 991px){
    .multipleform{
        flex-direction: column;
    }

   
}

